$nav-bar-heigth: 50px;

//Break points
$mobile: 768px;

//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);

.navbar{
  
  z-index: 4;
  position: absolute;
  width: 100vw;
  height: fit-content;

  &-container{

    position: relative;

    display: grid;
    grid-template-columns: 35% 65%;

    width: 85%;
    margin: 40px 7.5% 0 7.5%;

      //Mobile styles
    @media(max-width: $mobile){
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: calc(100% - 40px);
      margin: 40px 20px 0 20px;
    }
    &-logo{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      //Mobile styles
      @media(max-width: $mobile){
        width: 70%
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    &-items{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //Mobile styles
      @media(max-width: $mobile){
        //Visibility
        visibility: hidden;
        opacity: 0;
        //Positioning and display
        position: absolute;
        flex-direction: column;
        //Margins
        top: 150%;
        //Dimensions
        width: 100%;
        &.show-mobile{
          visibility: visible;
          opacity: 1;
        }
        //Transition
        transition: opacity 0.5s linear;
        //Background color
        background-color: $opaque-blue;
        //Border
        border: solid $light-blue 1px;
        z-index: 1000;
      }
      //Item styles
      &-item{
        //Hover
        cursor: pointer;
        
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 30%;

        //Mobile styles
        @media(max-width: $mobile){
          justify-content: flex-start;
          width: 100%;
          padding: 20px 5%;
        }

        //Direct item
        &-title{
          //Dimensions
          text-transform: uppercase;
          font-family: "Robot Mono";
          color: white;
          letter-spacing: 4px;
          
          @media(max-width: $mobile){
            font-size: 14px;
          }

          p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        //Link
        &-direct-link{
          margin: 0 0 0 10px;
          @media(max-width: $mobile){
            margin: 0 0 0 15px;
          }
          //Image
          img{
            height: 14px;
            width: auto;
          }
        }
        //Dropdown item
        &-dropdown{
          width: 100%;
          position: relative;
          //Title
          &-title{
            //display
            display: flex;
            flex-direction: row;
            //Text
            text-transform: uppercase;
            font-family: "Robot Mono";
            color: white;
            letter-spacing: 4px;
            @media(max-width: $mobile){
              font-size: 14px;
            }
            p{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            //Button
            img{
              margin: 0 0 0 10px;
              @media(max-width: $mobile){
                margin: 0 0 0 15px;
              }
            }
            //When dropdown is open
            &.open{
              color: $light-blue;
              img{
                transform: rotate(180deg);
              }
            }
          }
          //Items
          &-items{
            position: absolute;
            visibility: hidden;

            //Background color
            background-color: $opaque-blue;
            //Border
            border: solid $light-blue 1px;
                
            opacity: 0;
            //Is open
            &.open{
              visibility: visible;
              opacity: 1;
              margin: 15px 0 0 0;
            }
            
            //Transition
            transition: opacity 0.5s linear;
            
            //Mobile
            @media(max-width: $mobile){
              background-color: transparent;
              border: none;
              width: 100%;
              position: relative;
            }

            //Item
            &-item{
              //Display
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 15px 10px;
              letter-spacing: 4px;
              //Mobile
              @media(max-width: $mobile){
                font-size: 14px;
                justify-content: space-between;
                margin: 10px 0 0 0;
                padding: 10px 20px 10px 0;
              }
              //Title
              &-title{
                //Dimensions
                //Text
                text-transform: uppercase;
                font-family: "Robot Mono";
                font-weight: bold;
                color: white;
                //Overflow
                p{
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                //Mobile
                @media(max-width: $mobile){
                  width: 80%;
                  font-size: 13px;
                }
              }
              //button
              &-button{
                margin: 0 0 0 10px;
              }
            }
            
          }
        }
      }
    }
    &-right{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      //Mobile styles
      @media(max-width: $mobile){
        justify-content: space-around;
        width: 30%
      }
      //Hover image
      img{
        margin: 0 0 0 20px;
        cursor: pointer;
        @media(max-width: $mobile){
          margin: 0;
        }
      }
    }
  }

  //Search
  //Search bar
  &-search{
    position: absolute;
    right: 15%;
    top: 80px;
    //Input
    &-input{
      //Display
      display: flex;
      flex-direction: row;
      align-items: center;
      //Border
      padding: 10px;
      border: solid black 1px;
      border-radius: 10px 10px 0 0;
      //Color
      background-color: $opaque-blue;
      input{
        background-color: $opaque-blue;
        border: none;
        padding: 10px;
        color: white;
      }
      img{
        margin: 0 0 0 10px;
      }
      //Results
      &-result{
        width: 100%;
        height: 60px;
        padding: 0 20px;
        background-color: $opaque-blue;
        border: solid black 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        //Navbar
        .navbar-container-items-item-dropdown-items{
          z-index: 100;
          margin: 18px 0 0 0 !important;
          &-item{
            margin: 0 !important;
            padding: 10px 5px;
            width: 100%;
            background-color: $opaque-blue;
            border: solid black 1px;
          }
        }
      }
    }
  }
}