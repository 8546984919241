//Styles imports
@import './views/navbar.scss';
@import './views/footer.scss';
@import './views/home.scss';
@import './views/us.scss';
@import './views/industries.scss';
@import './views/services.scss';
@import './views/loading.scss';

//General styles
* {
  box-sizing: border-box;
  scroll-behavior: smooth;

  //Non draggable
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none;
  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}

html, body {
  width: 100%;
}

body {
  scroll-behavior: smooth;
  overflow: scroll;
  padding: 0;
  margin: 0;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

h1, h2, p, ul, hr{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

//fonts imports
//KARLA
@font-face{
  font-family: 'Karla';
  font-style: normal;
  src: local('Karla'), local('Karla'),
    url(../assets/fonts/Karla/Karla-Regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Karla Bold';
  font-style: normal;
  src: local('Karla Bold'), local('Karla Bold'),
    url(../assets/fonts/Karla/Karla-Bold.ttf) format('woff'), 
}
//MONTSERRAT
@font-face{
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Montserrat'), local('Montserrat'),
    url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Montserrat Light';
  font-style: normal;
  src: local('Montserrat Light'), local('Montserrat Light'),
    url(../assets/fonts/Montserrat/Montserrat-Light.ttf) format('woff'), 
}
@font-face{
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  src: local('Montserrat SemiBold'), local('Montserrat SemiBold'),
    url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('woff'), 
}
//POPPINS
@font-face{
  font-family: 'Poppins';
  font-style: normal;
  src: local('Poppins'), local('Poppins'),
    url(../assets/fonts/Poppins/Poppins-Regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins Thin';
  font-style: normal;
  src: local('Poppins Thin'), local('Poppins Thin'),
    url(../assets/fonts/Poppins/Poppins-Thin.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins ExtraLight';
  font-style: normal;
  src: local('Poppins ExtraLight'), local('Poppins ExtraLight'),
    url(../assets/fonts/Poppins/Poppins-ExtraLight.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins Light';
  font-style: normal;
  src: local('Poppins Light'), local('Poppins Light'),
    url(../assets/fonts/Poppins/Poppins-Light.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins SemiBold';
  font-style: normal;
  src: local('Poppins SemiBold'), local('Poppins SemiBold'),
    url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins Black';
  font-style: normal;
  src: local('Poppins Black'), local('Poppins Black'),
    url(../assets/fonts/Poppins/Poppins-Black.ttf) format('woff'), 
}
@font-face{
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  src: local('Poppins ExtraBold'), local('Poppins ExtraBold'),
    url(../assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('woff'), 
}
//ROBOTO
@font-face{
  font-family: 'Robot Mono Thin';
  font-style: normal;
  src: local('Robot Mono'), local('Robot Mono'),
    url(../assets/fonts/Roboto-Mono/RobotoMono-Thin.ttf) format('woff'), 
}
@font-face{
  font-family: 'Robot Mono';
  font-style: normal;
  src: local('Robot Mono'), local('Robot Mono'),
    url(../assets/fonts/Roboto-Mono/RobotoMono-Regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Robot Mono Bold';
  font-style: normal;
  src: local('Robot Mono Bold'), local('Robot Mono Bold'),
    url(../assets/fonts/Roboto-Mono/RobotoMono-Bold.ttf) format('woff'), 
}
//SOURCE SANS
@font-face{
  font-family: 'Source Sans';
  font-style: normal;
  src: local('Source Sans'), local('Source Sans'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Source Sans 200';
  font-style: normal;
  src: local('Source Sans 200'), local('Source Sans 200'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-200.ttf) format('woff'), 
}
@font-face{
  font-family: 'Source Sans 300';
  font-style: normal;
  src: local('Source Sans 300'), local('Source Sans 300'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-300.ttf) format('woff'), 
}
@font-face{
  font-family: 'Source Sans 600';
  font-style: normal;
  src: local('Source Sans 600'), local('Source Sans 600'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-600.ttf) format('woff'), 
}
@font-face{
  font-family: 'Source Sans 700';
  font-style: normal;
  src: local('Source Sans 700'), local('Source Sans 700'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-700.ttf) format('woff'), 
}
@font-face{
  font-family: 'Source Sans 900';
  font-style: normal;
  src: local('Source Sans 900'), local('Source Sans 900'),
    url(../assets/fonts/Source-Sans/Source-Sans-Pro-900.ttf) format('woff'), 
}
//SYNCOPATHE
@font-face{
  font-family: 'Syncopathe';
  font-style: normal;
  src: local('Syncopathe'), local('Syncopathe'),
    url(../assets/fonts/Syncopathe/Syncopate-Regular.ttf) format('woff'), 
}
@font-face{
  font-family: 'Syncopathe Bold';
  font-style: normal;
  src: local('Syncopathe Bold'), local('Syncopathe Bold'),
    url(../assets/fonts/Syncopathe/Syncopate-Bold.ttf) format('woff'), 
}