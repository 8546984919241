//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);
$light-gray: rgb(107, 112, 149);
$dark-gray: rgb(64, 58, 65);

//Break points
$mobile: 768px;
$extraSmall: 568px;

.footer{
  
  overflow: hidden;

  padding: 60px 0 20px 0;
  @media(max-width: $mobile){
    padding: 40px 0 20px 0;
    background-position: center;
    background-size: cover;
  }
  //Desktop distribution
  //Mobile distribution
  &-mobile{
    display: flex;
    flex-direction: column;
  }
  //Desktop distribution
  &-desktop{
    margin: 0 7.5% 0 7.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    //Left
    &-left{
      width: 60%;
    }
    //Right
    &-right{
      width: 40%;
    }

  }
  //Title
  &-title{
    line-height: 45px;
    @media(max-width: $mobile){
      width: 85%;
      margin: 0 7.5% 0 7.5%;
    }
    //Extra small
    @media(max-width: $extraSmall){
      //Not first child
      p{
        text-align: center !important;
        :not(:first-child){
          font-size: 45px !important;
        }
      }
    }
  }
  //Form
  &-form{
    position: relative;
    height: 100%;
    padding: 30px 10%;
    @media(max-width: $mobile){
      height: fit-content;
      padding: 20px 20px 30px 20px;
      width: 85%;
      margin: 20px 7.5% 0 7.5%;
    }
    //Texts
    &-texts{
      p{
        font-family: 'Source Sans';
        margin: 20px 0 0 0;
        font-size: 18px;
        color: $light-gray;
        @media(max-width: $mobile){
          font-size: 18px;
        }
      }
      //Last item of text
      &-last{
        margin: 5px 0 0 0 !important;
      }
    }
    //Separator
    &-separator{
      margin-top: 20px;
      width: 20%;
      background-color: $light-blue;
      border: none;
      height: 2px;
    }
    //Email
    &-email{
      //Margins and dimensions
      margin-top: 10px;
      width: 100%;
      //Display
      display: flex;
      flex-direction: row;
      //Text
      color: $dark-gray;
      text-transform: uppercase;
      font-family: 'Robot Mono';
      font-weight: bold;
      letter-spacing: 4px;
      //Overflow
      overflow: hidden;
      img{
        margin: 0 0 0 10px;
      }
    }
    //Phones
    &-phones{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      //Margins and dimensions
      margin-top: 20px;

      //Mobile
      @media(max-width: $mobile){
        flex-direction: column;
      }

      //Phone item
      &-phone{
        font-family: 'Robot Mono';
        font-weight: bold;
        color: $dark-gray;
        margin: 20px 10px 0 0;
      }
    }
    //Messenger icon
    &-messenger{
      height: 100px;
      width: 100px;
      position: absolute;
      bottom: -50px;
      right: -4%;
      border-radius: 50%;
      //Mobile
      @media(max-width: $mobile){
        position: absolute;
        bottom: -50px;
        right: 5%;
      }
      //On hover
      &:hover{
        cursor: pointer;
      }
    }
  }
  //Locations
  &-locations{
    margin: 60px 0 0 0;
    width: 90%;
    display: flex;
    flex-direction: row;

    @media(max-width: $mobile){
      width: 85%;
      margin: 40px 7.5% 0 7.5%;
    }

    //Offices and barns
    &-offices,&-barns{
      width: 50%;
      //Title
      &-title{
        //Text
        text-transform: uppercase;
        color: $dark-gray;
        font-family: "Montserrat SemiBold";
      }
      //Items
      &-items{
        
        margin-top: 30px;
        width: 80%;

        @media(max-width: $mobile){
          margin-top: 20px;
          width: 100%;
        }
        //Item
        &-item{
          margin-top: 20px;
          //Text
          font-family: 'Source Sans';
          margin-top: 20px;
          padding: 0 10px 0 0;
          color: $light-gray;
          font-size: 16px;
          line-height: 24px;
          @media(max-width: $mobile){
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  //Container
  &-container{

    display: grid;
    position: relative;

    grid-template-columns: 25% 65% 10%;
    margin: 50px 7.5% 0 7.5%;

    //Mobile
    @media(max-width: $mobile){
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 40px 7.5% 0 7.5%;
      width: 85%;
      justify-content: space-between;
    }
    //Logo
    &-logo{
        //Mobile
      @media(max-width: $mobile){
        width: 60%;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    //Items
    &-items{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin: 0 5%;
      @media(max-width: $mobile){
        flex-direction: column;
        //Visibility
        position: absolute;
        visibility: hidden;
        opacity: 0;
        //Positioning and display
        flex-direction: column;
        //Margins
        margin: -160px 7.5% 0 7.5%;
        //Dimensions
        width: 85%;
        &.show-mobile{
          visibility: visible;
          opacity: 1;
        }
        //Transition
        transition: opacity 0.5s linear;
        //Background color
        background-color: whitesmoke;
        //Border
        border: solid $dark-gray 1px;
      }
      //Item styles
      &-item{
        
        //Hover
        cursor: pointer;
        
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 30%;
        
        //Mobile styles
        @media(max-width: $mobile){
          justify-content: flex-start;
          width: 100%;
          padding: 20px 5%;
          z-index: 20;
        }
        //Direct item
        &-title{
          //Dimensions
          text-transform: uppercase;
          font-family: "Robot Mono Bold";
          color: $dark-gray;
          letter-spacing: 4px;
          p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @media(max-width: $mobile){
            font-size: 14px;
          }
        }
        //Link
        &-direct-link{
          margin: 0 0 0 10px;
          @media(max-width: $mobile){
            margin: 0 0 0 10px;
          }
        }
        //Dropdown item
        &-dropdown{
          position: relative;
          width: 100%;
          //Title
          &-title{
            //display
            display: flex;
            flex-direction: row;
            //Text
            text-transform: uppercase;
            font-family: "Robot Mono Bold";
            color: $dark-gray;
            letter-spacing: 4px;
            @media(max-width: $mobile){
              justify-content: flex-start;
              font-size: 14px;
            }
            p{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media(min-width: calc($mobile + 1px)){
                width: 80%;
              }
            }
            //Button
            img{
              transform: rotate(180deg);
              margin: 0 0 0 10px;
              @media(max-width: $mobile){
                margin: 0 0 0 10px;
              }
            }
            //Open
            &.open{
              img{
                transform: rotate(0);
              }
            }
          }
          //Items
          &-items{

            position: absolute;

            visibility: hidden;
            opacity: 0;
            //Is open
            &.open{
              visibility: visible;
              opacity: 1;
              width: 100%;
            }
            //Transition
            transition: opacity 0.5s linear;
            //Mobile
            @media(max-width: $mobile){
              width: 100%;
              left: 0;
            }
            //Item
            &-item{
              position: absolute;
              //Display
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              padding: 20px 5%;
              width: 150%;

              background-color: whitesmoke;
              border: solid black 1px;
              
              //Mobile
              @media(max-width: $mobile){
                width: 100%;
                justify-content: space-between;
                padding: 20px 5%;
              }
              //Title
              &-title{
                max-width: 80%;
                //Text
                text-transform: uppercase;
                font-family: "Robot Mono Bold";
                font-weight: bold;
                color: $dark-gray;
                letter-spacing: 4px;
                //Overflow
                p{
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                //Mobile
                @media(max-width: $mobile){
                  font-size: 14px;
                }
              }
              //Button
              &-button{
                margin: 0 0 0 10px;
              }
            }
          }
        }
      }
    }
    //Search
    &-search{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //Burger
    &-burger{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //Arrow
    &-goTop{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(-45deg);
    }
  }
  //Trademark
  &-trademark{
    width: 80%;
    margin: 40px 10% 10px 10%;
    text-align: center;
    color: $light-gray;
    //Mobile
    @media(max-width: $mobile){    
      width: 85%;
      margin: 20px 7.5% 0 7.5%;
    }
  }
  //Search bar
  &-search{
    position: absolute;
    right: 25%;
    //Input
    &-input{
      //Display
      display: flex;
      flex-direction: row;
      align-items: center;
      //Border
      padding: 10px;
      border: solid black 1px;
      border-radius: 0 0 10px 10px;
      //Color
      background-color: whitesmoke;
      input{
        background-color: whitesmoke;
        border: none;
        padding: 10px;
      }
      img{
        margin: 0 0 0 10px;
      }
      //Results
      &-result{
        width: 100%;
        height: 60px;
        background-color: white;
        border: solid black 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        //Footer
        .footer-container-items-item-dropdown-items{
          z-index: 100;
          &-item{
            padding: 7px 5px;
            width: 300%;
            background-color: whitesmoke;
            border: solid black 1px;
            //Mobile
            @media(max-width: $mobile){
              width: 100%;
            }
          }
        }
      }
    }
  }
}