$nav-bar-heigth: 150px;
$padding-header-desktop: 200px;

//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);
$light-gray: rgb(107, 112, 149);
$dark-gray: rgb(64, 58, 65);

//Break points
$mobile: 768px;
$extraSmall: 568px;

.home{

  overflow: hidden;

  //Header section
  &-header{

    //Position
    position: relative;
    
    //Default background
    background-color: $opaque-blue;
    
    //Display
    display: grid;
    grid-template-columns: 74% auto;
    
    //Mobile
    @media(max-width: $mobile){
      grid-template-rows: 81% auto;
      grid-template-columns: none;
    }
    
    //Grid
    &-grids{
      position: absolute;
      min-height: 100%;
      min-width: 100%;

      //Background
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      z-index: 1;
    }
    
    //Top and bottom
    &-top, &-bottom{
      display: flex;
      flex-direction: column;
      padding: $padding-header-desktop 0 70px 0;
      //Background
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      //Mobile
      @media(max-width: $mobile){
        padding: $nav-bar-heigth 0 0 0;
        background-repeat: no-repeat;
        background-size: cover auto;
        background-position-x: center;
        background-position-y: center;
      }

    }

    //Bottom specific
    &-bottom{
      background-size: auto;
        //Mobile styles
      @media(max-width: $mobile){
        background-size: cover;
        padding: 0;
      }
    }

    //Header item
    &-item{

      z-index: 3;
      display: none;
      &.selected {
        display: inline;
      }
        //Mobile styles
      @media(max-width: $mobile){
        width: 100%;
      }

      //Text container
      &-container {
          //Mobile styles
        @media(max-width: $mobile){
          text-align: center;
          margin: 0 10%;
          width: 80%;
        }

        //Title
        &-title{
          margin: 0 15% 0 10%;
          width: 75%;
          font-size: 65px;
          line-height: 75px;
          font-family: 'Syncopathe';
          color: white;
          font-weight: 400;
          //Mobile styles
          @media(max-width: $mobile){
            font-size: 55px;
            line-height: 65px;
            margin: 0;
            width: 100%;
          }
          //Extra semall
          @media(max-width: $extraSmall){
            font-size: 35px;
            line-height: 45px;
          }
        }

        //Description
        &-description{
          margin: 20px 30% 0 10%;
          width: 60%;
          font-size: 25px;
          line-height: 35px;
          font-family: 'Source Sans';
          color: white;
          //Mobile styles
          @media(max-width: $mobile){
            font-size: 21px;
            line-height: 36px;
            margin: 20px 0 0 0;
            width: 100%;
          }
        }
      }
    }

    //Header pagination
    &-paginator{
      
      z-index: 3;

      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 200px 0 0 40%;
      

      //Mobile styles
      @media(max-width: $mobile){
        position: inherit;
        margin: 30px 0 0 0;
      }

      //Page
      &-page{

        //Display
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        //Hover
        cursor: pointer;

        width: 40px;
        height: 40px;

        //Text
        color: white;
        font-family: 'Robot Mono';
        font-weight: bold;
        font-size: 11px;
        letter-spacing: 2px;

        &.selected{
          background-color: $light-blue;
        }
        //Mobile styles
        @media(max-width: $mobile){
          width: 50px;
          height: 50px;
        }
      }
    }

    //More
    &-more{
      margin: -40px 0 0 10%;
      z-index: 3;
      width: fit-content;

      //Separator
      //Mobile styles
      @media(max-width: $mobile){
        margin: 60px 25% 150px 25%;
        width: 50%;
      }
      &-separator{
        width: 60px;
        height: 2px;
        background-color: $light-blue;
        border: none;
      }
      //container
      &-container{
        display: flex;
        flex-direction: row;
        //Mobile styles
        @media(max-width: $mobile){
          justify-content: flex-start;
          margin: 0 0 20px 0;
        }
        //Text
        &-text{
          text-transform: uppercase;
          color: white;
          font-family: 'Robot Mono';
          font-size: 18px;
          letter-spacing: 5px;
          margin: 7px 0 0 0;
            //Mobile styles
          @media(max-width: $mobile){
            font-size: 16px;
            letter-spacing: 5px;
          }
        }
        //Direct
        &-direct{
          margin: 0 0 0 10px;
        }
      }
    }

    //Next
    &-next{
      //Hover
      cursor: pointer;
      
      z-index: 3;

      //Position
      position: relative;
      height: 100px;
      width: 100px;
      right: 50px;
      top: 16.5%;
      //Display
      display: flex;
      align-items: center;
      justify-content: center;
      //Border
      border: solid white 1px;
      border-radius: 50%;
      //Mobile
      @media(max-width: $mobile){
        height: 90px;
        width: 90px;
        top: -45px;
        right: 0;
        margin: 0 auto;
      }
      //Text
      &-text{
        font-size: 19px;
        letter-spacing: 6px;
        font-family: 'Robot Mono';
        color: white;
        text-transform: uppercase;
        //Mobile styles
        @media(max-width: $mobile){
          font-size: 18px;
          letter-spacing: 6px;
        }
      }
    }
  }
  //Section 2
  &-section2{
    padding: 40px 0 0 0;
    //Background
    background-position: center;
    background-size: contain;
    //Mobile
    @media(max-width: $mobile){
      padding: 40px 0 60px 0;
      background-position: center;
      background-size: cover;
    }
    //Description
    &-description{
      margin: 30px 0 0 0;
      width: 100%;
      //Mobile
      @media(max-width: $mobile){
        margin: 0;
        line-height: 29px;
        width: 100%;
      }
    }
    //Items
    &-items{

      display: flex;
      flex-direction: row;

      min-height: 600px;

      @media(max-width: $mobile){ 
        flex-direction: column;
        width: 85%;
        margin: 0 7.5% 0 7.5%;
      }

      //Left specifict
      &-left{
        margin: 0 2.5% 0 2.5%;
        @media(max-width: $mobile){
          margin: 0;
        }
      }

      //Right specific
      &-right{
        margin: 0 2.5%;
        @media(max-width: $mobile){
          margin: 0;
        }
      }

      //Items containers
      &-left,&-right{
        width: 30%;
        display: flex;
        flex-direction: column;
        @media(max-width: $mobile){
          width: 100%;
        }
        //Item
        &-item{
          display: flex;
          flex-direction: row;
          margin: 10% 0 0 0;
          //Mobile
          @media(max-width: $mobile){
            margin: 35px 0 0 0;
          }

          //Number
          &-number{
            //Dimensions
            width: 40px;
            height: 40px;
            //Display
            display: flex;
            align-items: center;
            justify-content: center;
            //Border
            border: solid $light-blue 1px;
            border-radius: 50%;
            //Text
            font-family: 'Robot Mono';
            color: $light-blue;
            font-weight: bold;
            font-size: 12px;

            //Mobile
            @media(max-width: $mobile){
              width: 45px;
              height: 45px;
              font-size: 15px;
            }
          }
          &-texts{
            display: flex;
            flex-direction: column;
            width: calc(100% - 50px);
            //Margins
            margin: 0 0 0 20px;
            //Title
            &-title{
              //Text
              font-family: 'Montserrat SemiBold';
              color: $dark-gray;
              //Text
              font-size: 13px;
              line-height: 18px;
              //Mobile
              @media(max-width: $mobile){
                //Text
                font-size: 16px;
                line-height: 26px;
              }
            }
            
            //Item description
            &-description{
              margin: 5px 0 0 0;
              font-family: 'Source Sans';
              color: $light-gray;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              //Mobile
              @media(max-width: $mobile){
                margin: 15px 0 0 0;
                //Text
                font-size: 18px;
                line-height: 26px;
              }
            }
          }
        }
      }

      //Center
      &-center{
        //Mobile
        @media(max-width: $mobile){
          margin-top: 50px;
        }
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  //WhyLuckyTrade
  &-whyLucky{
    margin: -60px 0 0 0;
    padding: 40px 0 0 0;
    @media(max-width: $mobile){
      margin: 0;
      padding: 30px 0 0 0;
      //Background
      background-position: center;
      background-size: cover;
    }
    //Title
    &-title{
      width: fit-content;
      margin: 60px 7.5% 0 auto;
      line-height: 65px;
      //Mobile
      @media(max-width: $mobile){
        width: 84%;
        margin: 0 8% 0 8%;
        line-height: 55px;
      }
    }
    //Items
    &-items{

      width: 85%;
      margin: -100px 7.5% 0 7.5%;

      position: relative;

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
      }

      //Item
      &-item{

        overflow: hidden;

        &:nth-of-type(odd){
          margin: -60px 0 0 0;
          //Mobile
          @media(max-width: $mobile){
            //Margins
            margin: 30px 0 0 0;
          }
        }

        &:nth-of-type(even){
          margin: -60px 0 0 55%;
          //Mobile
          @media(max-width: $mobile){
            //Margins
            margin: 30px 0 0 0;
          }
        }

        width: 45%;
        height: 300px;

        display: flex;
        flex-direction: row-reverse;

        //Mobile
        @media(max-width: $mobile){
          flex-direction: column;
          width: 100%;
          padding: 0 0 30px 0;
          //Margins
          margin: 30px 0 0 0;
          height: fit-content;
        }

        z-index: 1;
        //Background
        background-color: white;
        //Border
        border: solid $light-blue;
        border-width: 7px 0 0 0;
        //Image
        &-image{
          width: 30%;
          //Mobile
          @media(max-width: $mobile){
            width: 100%;
          }

          img{
            width: 100%;
          }
        }
        //Content
        &-content{

          display: flex;
          flex-direction: column;

          justify-content: space-around;

          
          width: 70%;
          //Mobile
          @media(max-width: $mobile){
            width: 100%;
          }
          
          //Number
          &-number{
            //Dimens
            width: 35px;
            height: 35px;
            margin: 20px 0 0 40px;
            //Display
            display: flex;
            align-items: center;
            justify-content: center;
            //Border
            border: solid $light-blue 1px;
            border-radius: 50%;
            //Text
            font-family: 'Robot Mono';
            font-weight: bold;
            color: $light-blue;
            font-size: 12px;
            //Mobile
            @media(max-width: $mobile){
              width: 45px;
              height: 45px;
              font-weight: bold;
              font-size: 14px;
              margin: 30px 0 0 30px;
            }
          }

          //Title
          &-title{

            //Text
            font-family: 'Montserrat SemiBold';
            color: $dark-gray;
            margin: 0 40px 0 40px;
            font-size: 15px;
            line-height: 25px;
            //Mobile
            @media(max-width: $mobile){
              margin: 40px 30px 0 30px;
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  //Industries
  &-industries{
    display: flex;
    margin: -90px 0 0 0;
    //Mobile
    @media(max-width: $mobile){
      margin: -90px 0 0 0;
      flex-direction: column;
    }
    //Main
    &-main{
      //Image
      width: 50%;
      @media(max-width: $mobile){
        width: 100%;
      }
      img{
        width: 100%;
        height: 100%;
        @media(max-width: $mobile){
          width: 100%;
          height: auto;
        }
      }
      //Pagination
      &-pagination{
        //Display
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin: -80px 0 0 12.5%;

        //Mobile
        @media(max-width: $mobile){
          justify-content: center;
          margin: -15px 0 0 0;
        }

        //Page
        &-page{
          //Hover
          cursor: pointer;

          //Dimensions
          width: 40px;
          height: 40px;

          //Background
          background-color: transparent;

          //Display
          display: flex;
          justify-content: center;
          align-items: center;

          //Text
          font-family: 'Robot Mono';
          color: white;
          font-size: 10px;
          letter-spacing: 1px;
          //Selected
          &.selected{
            background-color: $light-blue;
          }

            //Mobile
          @media(max-width: $mobile){
            font-size: 14px;
            width: 52px;
            height: 52px;
            margin: -75px 0 0 0;
          }

        }
      }
    }
    //Content
    &-content{
      display: flex;
      flex-direction: column;
      margin: 5% 0 0 0;
      width: 50%;
      @media(max-width: $mobile){
        margin: 50px 0 0 0;
        width: 100%;
      }
      //Title
      &-title{
        height: fit-content;
        margin: 0 0 0 7.5%;
        p, h1, h2, h3, h4, h5{
          margin: 8px 0 0 0;
        }
        //Mobile
        @media(max-width: $mobile){
          margin: 0 7.5% 0 7.5%;
          line-height: 60px;
          p, h1, h2, h3, h4, h5{
            margin: 0;
          }
        }
        //Extra small
        @media(max-width: $extraSmall){
          line-height: 45px;
          
          p, h1, h2, h3, h4, h5{
            text-align: center !important;
          }

          :not(:last-child) {
            span{
              font-size: 35px !important;
            }
          }
          
          :last-child{
            line-height: 30px;
          }

        }
      }
      //Menu
      &-menu{

        margin: 25% 0 0 0;
        padding: 0 20% 40px 20%;

        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        @media(max-width: $mobile){
          width: 100%;
          margin: 20px 0 0 0;
          padding: 0 7.5% 30px 7.5%;
          flex: none;
        }
        //Item
        &-item{
          //Hover
          cursor: pointer;
          margin: 40px 0 0 0;
          //Text
          font-family: 'Robot Mono';
          font-weight: 600;
          letter-spacing: 4px;
          color: white;
          text-transform: uppercase;
          //Selected
          &.selected{
            color: $light-blue;
          }
          //Mobile
          @media(max-width: $mobile){
            //Margins
            margin: 30px 0 0 0;
          }
        }
      }
    }
  }
  //Clients
  &-clients{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 70px 0 40px 0;
    //Background
    background-position: center;
    background-size: cover;
    //Mobile
    @media(max-width: $mobile){
      padding: 20px 0 40px 0;
      //Background
      background-position: right;
      background-size: cover;
    }
    //Grids
    &-grids{
      position: absolute;
      width: 100%;
      height: 100vh;
    }
    //Title
    &-title{
      //Mobile
      @media(max-width: $mobile){
        line-height: 46px;
      }
      //Extra small
      @media(max-width: $extraSmall){
        //Last child
        :last-child{
          span{
            font-size: 45px !important;
          }
        }
      }
    }
    //Carousel
    &-carousel{
      //Display
      display: flex;
      flex-direction: column;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
        align-items: center;
      }
      //Items
      &-items{

        height: 200px;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        //Mobile
        @media(max-width: $mobile){
          height: fit-content;
          margin: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 20%;
          width: 80%;
        }
        //Item
        &-item{
          //Display
          position: absolute;
          left: 0;
          align-items: center;
          justify-content: center;
          //Visibility
          visibility: collapse;
          height: 0;
          width: 0;
          opacity: 0;
          //Visible
          &.visible{
            position: relative;
            display: flex;
            height: auto;
            width: auto;
            visibility: visible;
            opacity: 1;
            height: 200px;
          }
          //Transition
          transition: display 0s, visibility 0s, opacity 1s linear;
          //Mobile
          @media(max-width: $mobile){
            height: 200px;
            width: 100%;
          }
          //Image
          img{
            width: 75%;
            height: auto;
          }
        }
      }
      //Pagiantion
      &-pagination{
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media(max-width: $mobile){
          margin: 20px 0 0 0;
        }
        //Page
        &-page{
          //Hover
          cursor: pointer;

          //Dimensions
          width: 40px;
          height: 40px;

          //display
          display: flex;
          align-items: center;
          justify-content: center;

          //Background
          background-color: transparent;

          //Text
          font-family: 'Robot Mono';
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 2px;
          color: $dark-gray;
          //Selected
          &.selected{
            background-color: $light-blue;
            color: white;
          }
          //Mobile
          @media(max-width: $mobile){
            font-size: 12px;
            //Dimensions
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  //Processes
  &-processes{
    padding: 40px 0 80px 0;
    //Background
    background-position: center;
    background-size: contain;
    //Mobile
    @media(max-width: $mobile){
      padding: 20px 0 30px 0;
      //Background
      background-position: center;
      background-size: cover;
    }
    //Title
    &-title{
      line-height: 60px;
      //Mobile
      @media(max-width: $mobile){
        margin: 0 7.5% 0 7.5%;
        width: 85%;
        line-height: normal;
        //Override rich text editor on mobile
        p{
          text-align: start !important;
        }
      }
      //Extra small
      @media(max-width: $extraSmall){
        //Last child
        :last-child{
          span{
            font-size: 45px !important;
          }
        }
      }
    }
    //Carousel
    &-carousel{
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      //Mobile
      @media(max-width: $mobile){
        margin: 20px 7.5% 0 7.5%;
        width: 85%;
      }
      
      //Items
      &-items{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        //Mobile
        @media(max-width: $mobile){
          display: flex;
          flex-direction: column;
        }
        //Item
        &-item{
          display: flex;
          flex-direction: column;
          //Border
          border: solid $light-blue;
          border-width: 7px 0 0 0;
          margin: 0 10% 0 10%;
          //Mobile
          @media(max-width: $mobile){
            margin: 40px 0 0 0;
          }
          //Image
          &-image{
            width: 100%;
            img{
              width: 100%;
              height: auto;
            }
          }
          //Title
          &-title{
            margin: 20px 0 0 0;
            //Text
            color: white;
            font-family: "Montserrat";
            letter-spacing: 0.5px;
            font-weight: 600;
            //Mobile
            @media(max-width: $mobile){
              font-size: 20px;
              line-height: 30px;
              margin: 20px 0 0 0;
            }
          }
        }
      }
      //Page
      &-page{
        //Hover
        cursor: pointer;

        //Dimensions
        width: 40px;
        height: 40px;
        //display
        display: flex;
        align-items: center;
        justify-content: center;
        //Background
        background-color: transparent;
        //Text
        font-family: 'Robot Mono';
        font-weight: bold;
        color: $dark-gray;
        font-size: 11px;
        letter-spacing: 2px;
        //Selected
        &.selected{
          background-color: $light-blue;
          color: white;
        }
        //Mobile
        @media(max-width: $mobile){
          font-size: 15px;
          //Dimensions
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}