$nav-bar-heigth: 150px;
$padding-header-desktop: 200px;

//Break points
$mobile: 768px;
$extraSmall: 568px;

//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);
$light-gray: rgb(107, 112, 149);
$light-gray-opaque: rgba(107, 112, 149, 0.5);
$dark-gray: rgb(64, 58, 65);

.services{

  overflow: hidden;
  
  //Header
  &-header{

    //Background
    padding: 180px 0 140px 0;
    background-size: cover;
    background-position: center;
    
    //Mobile
    @media(max-width: $mobile){
      padding: $nav-bar-heigth 0 100px 0;
      background-size: auto;
      background-size: cover, cover;
      background-position: center, left;
    }

    //Title
    &-title{

      width: 30%;
      margin: 0 0 0 55%;
      line-height: 45px;

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5%;
        line-height: 45px;
        p{
          text-align: center !important;
        }
      }
      //Extra small
      @media(max-width: $extraSmall){
        :first-child{
          span{
            font-size: 15px !important;
          }
        }
        :last-child{
          span{
            font-size: 30px !important;
          }
        }
      }
    }
    //Description
    &-description{
      width: 35%;
      margin: 20px 0 0 60%;
      line-height: 27px;
      //Mobile
      @media(max-width: $mobile){
        width: 80%;
        margin: 15px 10% 0 10%;
        line-height: 35px;
        p{
          text-align: center;
        }
      }
    }
  }

  //Services list
  &-list{

    display: grid;
    grid-template-columns: 2fr 1fr;

    padding: 80px 7.5% 80px 7.5%;

    //Background
    background-size: cover;
    background-position: center;

    //Mobile
    @media(max-width: $mobile){
      padding: 40px 0 40px 0;
      display: flex;
      flex-direction: column;
      background-position: left;
    }

    //Title
    &-title{
      
      line-height: 45px;

      //Mobile
      @media(max-width: $mobile){
        width: 90%;
        margin: 0 2.5% 0 7.5%;
        line-height: 48px;
      }
      //Extra small
      @media(max-width: $extraSmall){
        p{
          span{
            font-size: 35px !important;
          }
        }
      }
    }

    //Image
    &-image{

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 30px 7.5% 0 7.5%;
      }

      //Image
      img{
        width: 100%;
        height: auto;
      }
    }

    //Items
    &-items{
      
      margin: 30px 0 0 0;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 30px 7.5% 0 7.5%;
      }

      //Item
      &-item{

        margin: 20px 0 0 0;

        //Text
        color: white;
        font-family: 'Robot Mono';
        letter-spacing: 3px;

        //Selected
        &.selected{
          color: $light-blue;
        }

        //Mobile
        @media(max-width: $mobile){
          font-size: 16px;
          line-height: 28px;
        }
      }
    }

    //Description
    &-description{

      //Text
      color: white;
      font-family: 'Source Sans';
      
      margin: 40px 0 0 0;
      width: 65%;
      line-height: 28px;

      //Mobile
      @media(max-width: $mobile){
        margin: 25px 7.5% 0 7.5%;
        width: 85%;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  //Structure
  &-structure{

    //Background
    background-size: cover;
    background-position-y: bottom;
    background-position-x: right;

    //Mobile
    @media(max-width: $mobile){
      padding: 0 0 40px 0;
    }

    //Container
    &-container{

      display: flex;
      flex-direction: row;
      //Mobile
      @media(max-width: $mobile){
        flex-direction: column;
      }

      //Main
      &-main{

        width: 50%;

        //Mobile
        @media(max-width: $mobile){
          width: 100%;
        }

        //Image
        &-image{
          img{
            width: 100%;
            height: auto;
          }
        }

        //Footer
        &-footer{

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          
          margin: -80px 7.5% 0 15%;

          @media(max-width: $mobile){
            margin: -80px 7.5% 0 7.5%;
            width: 85%;
          }

          //Name
          &-name{

            //Text
            color: white;
            font-family: 'Montserrat SemiBold';

            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          //Pagination
          &-pagination{
            display: flex;
            flex-direction: row;
            //Page
            &-page{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 40px;

              //Text
              font-family: 'Robot Mono';
              font-size: 10px;
              letter-spacing: 2px;
              font-weight: bold;
              color: white;

              //Selected
              &.selected{
                background-color: $light-blue;
              }

              //Mobile
              @media(max-width: $mobile){
                height: 50px;
                width: 50px;
                font-size: 15px;
              }
            }
          }
        }
      }

      //Texts
      &-texts{

        margin: 80px 0 0 5%;
        width: 50%;

        //Mobile
        @media(max-width: $mobile){
          margin: 80px 7.5% 0 7.5%;
          width: 85%;
        }

        //title
        &-title{
          //Mobile
          @media(max-width: $mobile){
            margin: 0;
            line-height: 53px;
          }
          //Extra small
          @media(max-width: $extraSmall){
            line-height: 40px;
          }
          //Override rich text editor on mobile
          p, h1, h2, h3, h4, h5, h6 {
            span{
              //Mobile
              @media(max-width: $mobile){
                font-size: 45px !important;
              }
              //Extra small
              @media(max-width: $extraSmall){
                font-size: 28px !important;
              }
            }
          }
        }

        //Description
        &-description{

          margin: 25px 7.5% 0 0;
          line-height: 30px;

          //Mobile
          @media (max-width: $mobile) {
            margin: 25px 0 0 0;
            line-height: 30px;
          }
        }
      }
    }
  }

  //Support
  &-support{

    display: flex;
    flex-direction: row;

    margin: 100px 0 0 0;

    //Mobile
    @media(max-width: $mobile){
      flex-direction: column;
      padding: 40px 0 0 0;
    }

    //Texts
    &-texts{

      width: 42.5%;
      margin: 0 0 0 7.5%;

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
      }

      //Title
      &-title{
        line-height: 45px;
        //Mobile
        @media(max-width: $mobile){
        }
        //Extra small
        @media(max-width: $extraSmall){
          :last-child{
            span{
              font-size: 40px !important;
            }
          }
        }
      }

      //Description
      &-description{
        width: 70%;
        margin: 20px 0 0 0;
        line-height: 26px;
        //Mobile
        @media(max-width: $mobile){
          width: 100%;
          margin: 10px 0 0 0;
          line-height: 26px;
        }
      }
    }

    //Form
    &-form{

      position: relative;
      
      width: 42.5%;
      margin: 0 7.5% 0 0;
      padding: 40px 3% 40px 3%;

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 30px 7.5% 0 7.5%;
        padding: 10px 5% 35px 5%;
      }

      //Top
      &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        //Mobile
        @media(max-width: $mobile){
          flex-direction: column;
        }

        //Name
        &-name{
          width: 58%;
          //Mobile
          @media(max-width: $mobile){
            width: 100%;
          }
        }

        //Company
        &-company{
          width: 40%;
          //Mobile
          @media(max-width: $mobile){
            width: 100%;
          }
        }
      }

      //Inputs
      &-top-name, &-top-company, &-contact{
        margin: 15px 0 0 0;
        input{
          border: solid $light-gray-opaque 1px;
          padding: 20px 0 20px 25px;
          width: 100%;
          font-family: 'Robot Mono Bold';
          color: $light-gray;
          letter-spacing: 3px;
        }

        input[type=text]{
          color: red;
        }

        //Placeholders cross browser madness
        ::-webkit-input-placeholder {
          color: $light-gray;
        }
        :-moz-placeholder {
          /* FF 4-18 */
          color: $light-gray;
          opacity: 1;
        }
        ::-moz-placeholder {
          /* FF 19+ */
          color: $light-gray;
          opacity: 1;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          color: $light-gray;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $light-gray;
        }
        ::placeholder {
          /* modern browser */
          color: $light-gray;
        }
      }

      //Separator
      hr{
        width: 50px;
        height: 1px;
        border: none;
        background-color: $light-blue;
        margin: 30px 0 0 0;
        //Mobile
        @media(max-width: $mobile){
          margin: 25px 0 0 0;
        }
      }

      //Button
      &-send{
        display: flex;
        flex-direction: row;
        margin: 5px 0 0 0;

        //Mobile
        @media(max-width: $mobile){
          margin: 10px 0 0 0;
        }

        //Text
        input{
          padding: 0;
          border: none;
          background-color: transparent;
          color: $dark-gray;
          font-family: 'Robot Mono Bold';
          letter-spacing: 3px;
        }
        //Button
        img{
          margin: 0 0 0 10px;
        }
      }

      //Messenger
      &-messenger{
        position: absolute;
        bottom: -50px;
        right: 10px;
        border-radius: 50%;
        //On hover
        &:hover{
          cursor: pointer;
        }
      }

    }
  }

  //Footer
  &-footer{
    padding: 10px;
    &-form{
      &-texts{
        //Mobile
        @media(max-width: $mobile){
          text-align: center;
        }
        p{
          margin-top: 40px;
          @media(max-width: $mobile){
            margin-top: 40px;
          }
        }
        &-last{
          margin-top: 10px !important;
          //Mobile
          @media(max-width: $mobile){
            margin-top: 10px !important;
          }
        }
      }
    }
    //Separator
    hr{
      margin-top: 30px;
    }
    //Mobile
    &-mobile{
      flex-direction: column-reverse;
    }
  }

}