$nav-bar-heigth: 150px;
$padding-header-desktop: 200px;

//Break points
$mobile: 768px;

//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);
$light-gray: rgb(107, 112, 149);
$light-gray-opaque: rgba(107, 112, 149, 0.5);
$dark-gray: rgb(64, 58, 65);

.us{
  
  overflow: hidden;

  //Header
  &-header{

    padding: 180px 0 140px 0;
    //Background
    background-size: cover;
    background-position: center, top;

    //Mobile
    @media(max-width: $mobile){
      padding: $nav-bar-heigth 0 100px 0;
      background-size: auto;
      background-position: center;
    }

    //Title
    &-title{

      width: 30%;
      margin: 0 0 0 50%;
      line-height: 55px;

      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5%;
        line-height: 45px;
        p{
          text-align: center;
        }
      }
      //Extra small
      @media(max-width: $extraSmall){
        :not(:first-child){
          span{
            font-size: 30px !important;
          }
        }
      }
    }

    //Description
    &-description{
      width: 30%;
      margin: 20px 0 0 60%;
      line-height: 30px;
      //Mobile
      @media(max-width: $mobile){
        width: 80%;
        margin: 15px 10% 0 10%;
        line-height: 35px;
        p{
          text-align: center;
        }
      }
    }
  }

  //WhyLucky
  &-whyLucky{
    padding: 50px 0 0 0;
    margin: 0 0 -90px 0;

    //Background
    background-size: contain;
    background-position: center;
    //Mobile
    @media(max-width: $mobile){
      //Background
      background-size: cover;
      background-position: top;

      margin: 0 0 -80px 0;
      padding: 50px 0 0 0;
    }
    //Title
    &-title{
      width: 85%;
      margin: 0 7.5% 0 7.5%;
      line-height: 46px;
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
        line-height: 46px;
      }
    }
    //Items
    &-items{
      width: 85%;
      margin: 60px 7.5% 0 7.5%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
      //Mobile
      @media(max-width: $mobile){
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 20px 7.5% 0 7.5%;
      }
      //Item
      &-item{
        display: flex;
        flex-direction: column;

        padding: 30px;
        margin: 0 0 30px 0;
        
        //Background
        background-color: white;
        border: solid;
        border-color: $light-blue $light-gray-opaque $light-gray-opaque $light-gray-opaque;
        border-width: 7px 1px 1px 1px;

        //Mobile
        @media(max-width: $mobile){
          padding: 30px;
          margin: 30px 0 0 0;
        }

        //Number
        &-number{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          font-size: 14px;

          //Border
          border: solid 1px $light-blue;
          border-radius: 50%;

          //Text
          font-family: 'Robot Mono';
          font-weight: bold;
          color: $light-blue;
          font-size: 13px;

          //Mobile
          @media(max-width: $mobile){
            font-weight: bold;
            height: 50px;
            width: 50px;
            font-size: 17px;
          }
        }

        //Title
        &-title{

          //Text
          font-family: 'Montserrat SemiBold';
          text-transform: uppercase;
          color: $dark-gray;
          align-items: center;
          justify-content: flex-end;
          line-height: 35px;
            padding: 30px 0 10px 0;
          //Mobile
          @media(max-width: $mobile){
            font-size: 15px;
            line-height: 30px;
            padding: 30px 0 30px 0;
          }
        }
      }
    }
  }

  //Clients
  &-clients{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 100px 0 40px 0;
    //Background
    background-position: center;
    background-size: cover;
    //Mobile
    @media(max-width: $mobile){
      //Background
      background-position: right;
      background-size: cover;
    }
    //Grids
    &-grids{
      position: absolute;
      width: 100%;
      height: 100vh;
    }
    //Title
    &-title{
      //Mobile
      @media(max-width: $mobile){
        width: 95%;
        margin: 0 0 0 2.5%;
        line-height: 46px;
      }
      //Extra small
      @media(max-width: $extraSmall){
        :not(:first-child){
          span{
            font-size: 40px !important;
          }
        }
      }
    }
    //Carousel
    &-carousel{
      //Display
      display: flex;
      flex-direction: column;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
      }
      //Items
      &-items{

        height: 200px;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        //Mobile
        @media(max-width: $mobile){
          height: fit-content;
          margin: 0;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-template-rows: repeat();
          width: 100%;
        }
        //Item
        &-item{
          //Display
          position: absolute;
          left: 0;
          align-items: center;
          justify-content: center;
          //Visibility
          visibility: collapse;
          height: 0;
          width: 0;
          opacity: 0;
          //Visible
          &.visible{
            position: relative;
            display: flex;
            height: auto;
            width: auto;
            visibility: visible;
            opacity: 1;
            height: 200px;
          }
          //Transition
          transition: display 0s, visibility 0s, opacity 1s linear;
          //Mobile
          @media(max-width: $mobile){
            height: 200px;
            width: 100%;
          }
          //Image
          img{
            width: 75%;
            height: auto;
          }
        }
      }
      //Pagiantion
      &-pagination{
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media(max-width: $mobile){
          margin: 20px 0 0 0;
        }
        //Page
        &-page{
          //Hover
          cursor: pointer;

          //Dimensions
          width: 40px;
          height: 40px;

          //display
          display: flex;
          align-items: center;
          justify-content: center;

          //Background
          background-color: transparent;

          //Text
          font-family: 'Robot Mono';
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 2px;
          color: $dark-gray;
          //Selected
          &.selected{
            background-color: $light-blue;
            color: white;
          }
          //Mobile
          @media(max-width: $mobile){
            font-size: 12px;
            //Dimensions
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  //Providers
  &-providers{
    padding: 70px 0 40px 0;
    
    //Background
    background-size: cover;
    background-position-x: center;

    //Mobile
    @media(max-width: $mobile){
      padding: 20px 0 40px 0;
      //Background
      background-size: auto;
      background-position: right;
    }
    //Title
    &-title{
      //Mobile
      @media(max-width: $mobile){
        width: 95%;
        margin: 0 0 0 2.5%;
        line-height: 46px;
      }
      //Extra small
      @media(max-width: $extraSmall){
        :not(:first-child){
          span{
            font-size: 40px !important;
          }
        }
      }
    }
    //Desciription
    &-description{
      margin: 20px 0 0 0;
      //Mobile
      @media(max-width: $mobile){
        display: none;
      }
    }
    //Carousel
    &-carousel{
      //Display
      display: flex;
      flex-direction: column;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5% 0 7.5%;
      }
      //Items
      &-items{

        height: 200px;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        //Mobile
        @media(max-width: $mobile){
          height: fit-content;
          margin: 0;
          display: grid;
          grid-template-columns: repeat(2, auto);
          grid-template-rows: repeat();
          width: 100%;
        }
        //Item
        &-item{
          //Display
          position: absolute;
          left: 0;
          align-items: center;
          justify-content: center;
          //Visibility
          visibility: collapse;
          height: 0;
          width: 0;
          opacity: 0;
          //Visible
          &.visible{
            position: relative;
            display: flex;
            height: auto;
            width: auto;
            visibility: visible;
            opacity: 1;
            height: 200px;
          }
          //Transition
          transition: display 0s, visibility 0s, opacity 1s linear;
          //Transition
          transition: opacity 0.5s linear;
          //Mobile
          @media(max-width: $mobile){
            height: 200px;
            width: 100%;
          }
          //Image
          img{
            width: 75%;
            height: auto;
          }
        }
      }
      //Pagiantion
      &-pagination{
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media(max-width: $mobile){
          margin: 20px 0 0 0;
        }
        //Page
        &-page{
          //Hover
          cursor: pointer;

          //Dimensions
          width: 40px;
          height: 40px;
          //display
          display: flex;
          align-items: center;
          justify-content: center;
          //Background
          background-color: transparent;
          //Text
          font-family: 'Robot Mono';
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 2px;
          color: white;
          //Selected
          &.selected{
            background-color: $light-blue;
          }
          //Mobile
          @media(max-width: $mobile){
            font-size: 15px;
            //Dimensions
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  //Know more
  &-more{

    padding: 50px 0 100px 0;
    
    //Background
    background-size: contain, contain;
    background-position: center;
    background-repeat: repeat-y, repeat;

    @media(max-width: $mobile){
      padding: 40px 0 60px 0;
    }

    //Title
    &-title{

      margin: 0 30% 0 30%;

      //Mobile
      @media(max-width: $mobile){
        width: 80%;
        margin: 0 10% 0 10%;
      }
      //Extra small
      @media(max-width: $extraSmall){
        p{
          span{
            font-size: 40px !important;
          }
        }
      }
    }

    //Items
    &-items{

      margin: 50px 7.5% 0 7.5%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 60px;

      //Mobile
      @media(max-width: $mobile){
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 50px 7.5% 0 7.5%;
      }

      //Item
      &-item{
        //Border
        border: solid $light-blue;
        border-width: 7px 0 0 0;

        display: flex;
        flex-direction: column;
        
        //Mobile
        @media(max-width: $mobile){
          margin: 50px 0 0 0;
        }

        //Image
        &-image{
          img{
            width: 100%;
            height: auto;
          }
        }

        //Title
        &-title{
          margin: 30px 0;
          //Text
          color: white;
          font-family: 'Montserrat SemiBold';
          font-size: 17px;
          line-height: 27px;
          letter-spacing: 1px;
          //Mobile
          @media(max-width: $mobile){
            margin: 10px 0 0 0;
            font-size: 18px;
            line-height: 25px;
          }
        }

        //Separator
        hr{
          margin: auto 0 0 0;
          border: none;
          background-color: $light-blue;
          width: 55px;
          height: 2px;
          //Mobile
          @media(max-width: $mobile){
            margin: 15px 0 0 0;
          }
        }

        //Link
        &-link{
          //Dimenstions
          margin: 5px 0 0 0;
          font-size: 17px;
          line-height: 27px;
          //Mobile
          @media(max-width: $mobile){
            margin: 5px 0 0 0;
            font-size: 17px;
            line-height: 27px;
          }
          //Dropdown
          &-dropdown {
            cursor: default;
            //Title
            &-title{
              //Text
              color: white;
              font-family: 'Robot Mono';
              letter-spacing: 4px;
              //Display
              display: flex;
              flex-direction: row;
              p{
                //Hover
                cursor: pointer;
              }
              //Image
              img{
                //Hover
                cursor: pointer;
                margin: 0 0 0 10px;
              }
            }
            //Items
            &-items{
              //Visibility
              visibility: hidden;
              opacity: 0;
              &.open{
                visibility: visible;
                opacity: 1;
              }
              transition: visibility 0s, height 0s, opacity 1s linear;
              //Position
              background-color: white;
              position: absolute;

              margin: 10px 0 0 0;
              padding: 0 7% 20px 30px;
              
              //Box shadow
              box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);

              //Mobile
              @media(max-width: $mobile){
                margin: 10px 0 0 0;
                padding: 5px 5% 20px 5%;
                width: 85%;
              }
              //Item
              &-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 20px 0 0 0;
                //Mobile
                @media(max-width: $mobile){
                  margin: 20px 0 0 0;
                }
                //Title
                &-title{
                  //Text
                  text-transform: uppercase;
                  color: $dark-gray;
                  font-family: 'Robot Mono';
                  //Mobile
                  @media(max-width: $mobile){
                    font-size: 18px;
                    line-height: 28px;
                  }
                }
                //Button
                &-button{
                  margin: 0 0 0 10px;
                }
              }
            }
          }
          //Direct
          &-direct{
            //Anchor
            &-anchor{
              cursor: default;
              display: flex;
              flex-direction: row;
            }
            //Title
            &-title{
              //Hover
              cursor: pointer;
              //Text
              color: white;
              font-family: 'Robot Mono';
              letter-spacing: 4px;
              //Display
              display: flex;
              flex-direction: row;
            }
            //Image
            &-link{
              //Hover
              cursor: pointer;
              margin: 0 0 0 10px;
            }
          }
          //Email
          &-email{
            display: flex;
            flex-direction: row;
            p{
              //Text
              color: white;
              font-family: 'Robot Mono';
              letter-spacing: 4px;
              //Display
              display: flex;
              flex-direction: row;
            }
            img{
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }
}