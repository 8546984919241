//Colors
$light-blue: rgb(0, 170, 227);

//Load animation
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading{
  //Display
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //Position
  position: fixed;
  z-index: 100000;
  //Dimension
  width: 100%;
  height: 100vh;
  //Colors
  background-color: white;
  //Scroll
  overflow: hidden;
  scroll-behavior: auto;

  //Spinner
  &-spinner{
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #FFFFFF;
    background: -moz-linear-gradient(left, #FFFFFF 10%, $light-blue 42%);
    background: -webkit-linear-gradient(left, #FFFFFF 10%, $light-blue 42%);
    background: -o-linear-gradient(left, #FFFFFF 10%, $light-blue 42%);
    background: -ms-linear-gradient(left, #FFFFFF 10%, $light-blue 42%);
    background: linear-gradient(to right, #FFFFFF 10%, $light-blue 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  &-spinner:before{
    width: 50%;
    height: 50%;
    background: #FFFFFF;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &-spinner:after{
    background: #FFFFFF;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}