$nav-bar-heigth: 150px;
$padding-header-desktop: 200px;

//Break points
$mobile: 768px;
$extraSmall: 568px;

//Colors
$light-blue: rgb(0, 170, 227);
$opaque-blue: rgb(7, 98, 129);
$light-gray: rgb(107, 112, 149);
$dark-gray: rgb(64, 58, 65);


.industries{

  overflow: hidden;
  
  //Header
  &-header{

    //Background
    padding: 230px 0 180px 0;
    background-size: cover;
    background-position: center, top;

    //Mobile
    @media(max-width: $mobile){
      padding: $nav-bar-heigth 0 150px 0;
      background-size: cover;
    }

    //Grid


    //Title
    &-title{
      font-size: 65px;
      line-height: 75px;
      //Text
      color: white;
      text-align: center;
      font-family: 'Syncopathe';
      text-transform: uppercase;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5%;
        font-size: 40px;
        line-height: 50px;
      }
    }

    //Description
    &-description{
      //Text
      color: white;
      text-align: center;
      font-family: 'Source Sans';
      font-size: 23px;
      line-height: 35px;
      width: 60%;
      margin: 15px 20% 0 20%;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 15px 7.5% 0 7.5%;
        font-size: 23px;
        line-height: 35px;
      }
    }
  }

  //Focus section
  &-focus{
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 40px 7.5% 100px 7.5%;
    //Background
    background-size: cover;
    background-position: center;
    //Mobile
    @media(max-width: $mobile){
      display: flex;
      flex-direction: column;
      padding: 0 0 60px 0;
      //Background
      background-size: cover;
      background-position: right;
    }

    //Left container
    &-left{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      //Mobile
      @media(max-width: $mobile){
        display: flex;
        flex-direction: column-reverse;
        width: 85%;
        margin: 0 7.5%;
      }
    }

    //Right Container
    &-right{
      display: flex;
      flex-direction: column;
      margin: 0 0 0 7.5%;
      //Mobile
      @media(max-width: $mobile){
        width: 85%;
        margin: 0 7.5%;
      }
    }
    
    //Items
    &-items{
      display: flex;
      flex-direction: column;
      //Mobile
      @media(max-width: $mobile){
        margin: 30px 0 0 0;
        display: flex;
        flex-direction: column;
      }
      //Title
      &-title{
        line-height: 45px;
        //Mobile
        @media(max-width: $mobile){
          line-height: 40px;
        }
        //Extra small
        @media(max-width: $extraSmall){
          :not(:first-child){
            span{
              font-size: 45px !important;
            }
          }
        }
      }
      //List
      &-list{
        flex: 1 1 auto;
        &.right{
          height: 100%;
          //Mobile
          @media(max-width: $mobile){
            height: fit-content;
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        //Mobile
        @media(max-width: $mobile){
          display: flex;
          flex-direction: column;
        }
        //Item
        &-item{
          margin: 30px 0 0 0;
          //Display
          display: none;
          //Visible
          &.visible{
            display: flex;
          }
          flex-direction: row;
          //Mobile
          @media(max-width: $mobile){
            margin: 30px 0 0 0;
          }
          //Number
          &-number{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;

            //Border
            border: solid 1px $light-blue;
            border-radius: 50%;

            //Text
            font-family: 'Robot Mono Bold';
            color: $light-blue;
            font-size: 12px;

            //Mobile
            @media(max-width: $mobile){
              height: 50px;
              width: 50px;
              font-size: 17px;
            }
          }
          //Title
          &-title{
            display: flex;
            width: calc(100% - 50px);
            padding: 0 5% 0 15px;
            //Text
            font-size: 15px;
            line-height: 25px;
            font-family: 'Montserrat SemiBold';
            color: $dark-gray;
            align-items: center;
            //Mobile
            @media(max-width: $mobile){
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
      }
    }

    //Images
    &-images{
      position: relative;
      //Mobile
      @media(max-width: $mobile){
        margin: -60px 0 0 0;
      }
      //Item
      &-item{
        width: 100%;
        height: 100%;
        position: absolute;

        //Alignment
        display: flex;
        align-items: center;
        justify-content: center;
        
        //Visibility
        visibility: collapse;
        opacity: 0;

        //Selected
        &.selected{
          visibility: visible;
          opacity: 1;
        }
        
        transition: visibility 2s, opacity 2s ease-in-out;

        img{
          width: 90%;
          max-width: 437px;
          height: auto;
        }

        //Mobile
        @media(max-width: $mobile){
          position: absolute;
          top: 0;
          //Selected
          &.selected{
            position: relative;
          }
        }
      }
    }
  }

  //Products
  &-products{

    padding: 80px 0 120px 0;
    //Background
    background-size: cover;
    background-position: center;
    //Mobile
    @media(max-width: $mobile){
      padding: 30% 0 140px 0;
      //Background
      background-size: auto;
      background-position: center;
    }

    //Top
    &-top{
      
      display: flex;
      flex-direction: row;
      
      align-items: center;

      width: 85%;
      margin: 0 7.5%;
      
      @media(max-width: $mobile){
        flex-direction: column;
        width: 85%;
        margin: 0 7.5%;
      }
    }

    //Title
    &-title{
      width: 70%;
      line-height: 50px;
      @media(max-width: $mobile){
        width: 100%;
        line-height: 45px;
      }
      //Extra small
      @media(max-width: $extraSmall){
        :not(:first-child){
          span{
            font-size: 35px !important;
          }
        }
      }
    }

    //Description
    &-description{
      width: 30%;
      line-height: 30px;
      @media(max-width: $mobile){
        width: 100%;
        line-height: 30px;
      }
    }

    //List
    &-list{

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: end;
      width: 90%;
      margin: 0 5% 0 5%;

      //Mobile
      @media(max-width: $mobile){
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 15px 7.5% 0 7.5%;
      }

      //Item
      &-item{
        margin: 50px 7.5% 0 7.5%;
        //Display
        display: flex;
        flex-direction: row;
        //Background
        background-color: white;
        border: solid $light-blue;
        border-width: 7px 0 0 0;
        padding: 20px;
        //Empty
        &.empty{
          border: none;
          background-color: transparent;
        }
        //Mobile
        @media(max-width: $mobile){
          padding: 10px;
          margin: 40px 0 0 0;
        }
        //Number
        &-number{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          width: 35px;

          //Border
          border: solid 1px $light-blue;
          border-radius: 50%;

          //Text
          font-family: 'Robot Mono Bold';
          color: $light-blue;
          font-size: 12px;

          //Mobile
          @media(max-width: $mobile){
            height: 50px;
            width: 50px;
            font-size: 17px;
          }
        }
        //Title
        &-title{
          padding: 0 5% 0 15px;
          display: flex;
          width: calc(100% - 50px);
          //Text
          font-family: 'Montserrat SemiBold';
          text-transform: uppercase;
          color: $dark-gray;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          //Mobile
          @media(max-width: $mobile){
            font-size: 15px;
            line-height: 25px;
            padding: 0 5% 0 15px;
          }
        }
      }
    }
  }

  //Other industries
  &-other{

    padding: 60px 0 0 0;

    //Mobile
    @media(max-width: $mobile){
      padding: 0;
      //Background
      background-size: auto;
      background-position: center;
    }

    //Container
    &-container{

      display: grid;
      grid-template-columns: 1fr 1.5fr;
      width: 85%;
      margin: 0 7.5%;

      //Mobile
      @media(max-width: $mobile){
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 0 7.5%;
      }

      //Left container
      &-left{

        margin: -120px 0 0 0;

        //Mobile
        @media(max-width: $mobile){
          margin: -80px 0 0 0;
        }

        //Image
        &-image{
          img{
            width: 100%;
            height: auto;
          }
        }

      }

      //Right container
      &-right{

        margin: 0 15%;

        //Mobile
        @media(max-width: $mobile){
          margin: 40px 0 0 0;
        }
        //Title
        &-title{
          line-height: 50px;
          //Mobile
          @media(max-width: $mobile){
            line-height: 45px;
          }
          //Extra small
          @media(max-width: $extraSmall){
            :not(:first-child){
              span{
                font-size: 40px !important;
              }
            }
          }
        }
        //Description
        &-description{
          margin: 40px 0 0 0;
          line-height: 30px;
          //Mobile
          @media(max-width: $mobile){
            margin: 20px 10% 0 0;
            line-height: 30px;
          }
        }
        //Separator
        hr{
          background-color: $light-blue;
          border: none;
          margin: 30px 0 0 0;
          width: 10%;
          height: 2px;
          @media(max-width: $mobile){
            width: 30%;
            margin: 20px 0 0 0;
          }
        }
        //Email
        &-email{
          margin: 10px 0 0 0;
          display: flex;
          flex-direction: row;
          //Text
          color: $dark-gray;
          text-transform: uppercase;
          font-family: 'Robot Mono Bold';
          letter-spacing: 4px;
          //Mobile
          @media(max-width: $mobile){
            margin: 10px 0 0 0;
            line-height: 25px;
          }
          //Direct
          img{
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
}